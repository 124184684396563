.icon-16 { height: $icon-16 !important; }
.icon-18 { height: $icon-18 !important; }
.icon-20 { height: $icon-20 !important; }
.icon-24 { height: $icon-24 !important; }

a .icon, button .icon {
	cursor: pointer;
	transition: $transition;
	
	&:hover, &:focus {
		opacity: 0.7;
	}
}