.container {
	max-width: 836px;
	margin: 0 auto;
	padding-left: 1rem;
	padding-right: 1rem;
}

.container-lg {
	@extend .container;

	max-width: 1172px;
}

.container-sm {
	@extend .container;

	max-width: 572px;
}