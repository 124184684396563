html, body {
	font-family: 'Karla', sans-serif;
	font-size: 16px;
	line-height: 1.3;
	color: $dark;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
	font-family: 'Markazi Text', serif;
	line-height: 1.2;
}

h1, .h1 { font-size: $h1; line-height: 0.7; }
h2, .h2 { font-size: $h2; }
h3, .h3 { font-size: $h3; }
h4, .h4 { font-size: $h4; }
h5, .h5 { font-size: $h5; }
h6, .h6 { font-size: $h6; }

a { text-decoration: none; }

a:not(.btn):not(.img-link) {
	color: $primary;
	transition: $transition;
	font-weight: $font-weight-medium;
	position: relative;

	&::after {
		content: '';
		position: absolute;
		width: 100%;
		height: 2px;
		bottom: -0.2em;
		left: 0;
		transform: scaleX(0);
		background-color: $primary;
		transform-origin: bottom right;
		transition: transform 0.25s ease-out,
								background-color 0.15s ease-in-out;
	}

	&:hover {
		color: rgba($primary, 0.8);

		&::after {
			background-color: rgba($primary, 0.8);
			transform: scaleX(1);
			transform-origin: bottom left;
		}
	}

	&:focus-visible {
		outline: 2px solid $gray-300;
		border-radius: $radius-sm;
	}
}

p { margin-bottom: 0.25rem; }

strong { font-weight: $font-weight-bolder; }

.large { font-size: 1.125em; }
.small { font-size: 0.875em; }

.title {
	@extend h1;
	line-height: 0.75;

	span {
		@extend h2;
		display: block;
		line-height: 0.75;
	}
}

.title-primary {
	color: $primary;
	span { color: $secondary; }
}

.title-secondary {
	color: $white;
	span { color: $secondary; }
}

.text-green { color: $green-500; }
.text-yellow { color: $yellow-500; }
.text-peach { color: $peach-500; }

.font-weight-light { font-weight: $font-weight-light; }
.font-weight-regular { font-weight: $font-weight-regular; }
.font-weight-medium { font-weight: $font-weight-medium; }
.font-weight-bold { font-weight: $font-weight-bold; }
.font-weight-bolder { font-weight: $font-weight-bolder; }

.text-align-left { text-align: left !important; }
.text-align-center { text-align: center !important; }
.text-align-right { text-align: right !important; }
.text-align-justify { text-align: justify !important; }