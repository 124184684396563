//~~	xs:		<576		--	Mobile  ~~~~~~~~~~~~~~~~//

////																							



//~~	sm:		>=576		--	Lg Mobile  ~~~~~~~~~~~~~//

////																							



//~~	md:		>=768		~~	Tablet  ~~~~~~~~~~~~~~~~//

////																							



//~~	lg:		>=992		~~	Sm Desktop  ~~~~~~~~~~~~//
@media (min-width: 992px) {
	//--	nav  --//
	.navbar {
		padding: 1rem 0;
		grid-auto-flow: column;
		gap: 3rem;
		align-content: center;
		background: $white;

		position: unset;
		height: unset;
		width: unset;
		min-width: unset;
		max-width: unset;
	}
	.navbar-nav {
		grid-auto-flow: column;
		gap: 3rem;

		&.secondary { padding-top: unset; }
	}
	////																						
	
	//--	footer  --//
	.footer {
		padding-left: 0;
		padding-right: 0;
		grid-auto-flow: column;
		gap: 3rem;

		.col:nth-child(1) { grid-area: 1 / 1 / 2 / 2; }
		.col:nth-child(2) { grid-area: 1 / 2 / 2 / 3; }
		.col:nth-child(3) { grid-area: 1 / 3 / 2 / 4; }
		.col:nth-child(4) { grid-area: 1 / 4 / 2 / 5; }
		
		.logo { width: 6.75rem; }
	}
	////																						
	
	//--	hero  --//
	#hero {
		
		&::before {
			background-position: 88% 50%;
			background-size: 25vh;
		}

		&::after {
			background-position: 102% -150%;
			background-size: 16vh;
		}

		&.hero-home::after { background-position: 102% 490%; }

		.container {
			grid-auto-flow: column;
			grid-auto-columns: 3fr 2fr;
			gap: 5rem;
		}

		h1 { margin-bottom: 2rem; }
		p:not(:last-child) { margin-bottom: 2rem; }
	}

	.hero-home {
		margin-bottom: 8rem;

		img { aspect-ratio: 3 / 4 !important; }
	}
	////																						
	
	//--	routes  --//
	//		home  	//
	#specials .specials {
		grid-template-columns: repeat(3, minmax(0, 1fr));
	}

	#reviews {
		&::before {
			background-position: 90% 0%;
		background-size: 40vh;
		}

		&::after {
			background-position: 10% 60%;
			background-size: 40vh;
		}

		.reviews {
			grid-template-columns: repeat(4, minmax(0, 1fr));
			gap: 2rem;
		}
	}

	#about .container { grid-auto-columns: repeat(2, minmax(0, 1fr)); }



	//		reservation  	//
	#reservation-confirmation {
		.row {
			grid-auto-flow: column;
			grid-auto-columns: 3fr 4fr;
		}
		
		.col:nth-child(1) { grid-area: 1 / 1 / 2 / 2; }
		.col:nth-child(2) { grid-area: 1 / 2 / 2 / 3; }
	}
	////																						
}

	
////																							



//~~	xl:		>=1200	~~	Desktop  ~~~~~~~~~~~~~~~//

////																							



//~~	xxl:	>=1400	~~	Lg Desktop  ~~~~~~~~~~~~//

////																							