footer {
	background: $green-100;
	margin-top: 4rem;
	padding: 3rem 0;
}

.footer {
	padding: 0 1rem;
	display: grid;
	gap: 2rem;

	.col:nth-child(1) { grid-area: 1 / 1 / 2 / 2; }
	.col:nth-child(2) { grid-area: 1 / 2 / 2 / 3; }
	.col:nth-child(3) { grid-area: 2 / 1 / 3 / 3; }
	.col:nth-child(4) { grid-area: 3 / 1 / 4 / 3; }

	.logo { width: 4rem; }

	h6 {
		font-family: $font-family-sans-serif;
		font-size: $h5;
		font-weight: $font-weight-bold;
		margin-bottom: 1rem;
	}

	p {
		margin-top: 1rem;
		margin-bottom: 1rem;
	}
}

.social {
	display: grid;
	grid-template-columns: repeat(5, 1.5rem);
	gap: 0.75rem;
	align-items: center;
	justify-content: start;
}

.heel {
	@extend .footer;
	grid-template-columns: auto auto;
	justify-content: space-between;

	margin-top: 2rem;
	border-top: 1px solid $green-200;
	padding-top: 1rem;

	p {
		font-size: 0.75rem;
		color: $green-400;
		margin: 0;
	}
}