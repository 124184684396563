.btn {
	display: inline-block;
	height: fit-content;
	padding: 1rem 1.5rem;
	border: none;
	border-radius: $radius-pill;
	line-height: 0.6;
	font-family: $font-family-sans-serif;
	font-weight: $font-weight-medium;
	transition: $transition;

	.icon {
		position: relative;
		height: 0.875em;
	}
}

.btn-primary {
	background: $primary;
	color: $white;

	&:hover {
		background: $secondary;
		color: $dark;
	}

	&:disabled {
		background: $green-200;
		color: $gray-500;
	}
}

.btn-secondary {
	background: $secondary;
	color: $dark;

	&:hover {
		background: $primary;
		color: $white;
	}

	&:disabled {
		background: $yellow-200;
		color: $gray-500;
	}
}

.btn-subtle {
	background: $light;
	color: $primary;

	&:hover {
		background: $primary;
		color: $white;
	}

	&:disabled {
		background: $green-200;
		color: $gray-500;
	}
}

.btn-icon {
	display: grid;
	grid-auto-flow: column;
	column-gap: 0.5rem;
	justify-content: start;
	align-items: center;
}

.btn-link {
	padding: 0;
	border-radius: 0;
	background: transparent;
	border: none;
	font-weight: 500;
	color: $primary;

	&:hover { opacity: 0.7; }
}

.btn-dark {
	&.btn-primary {
		background: $tertiary;
		color: $dark;

		&:hover { background: $peach-200; }
	}
}

// .btn-text {
// 	padding: 0;
// 	color: $primary;
// 	background: transparent;
// 	border: none;
// }