input, textarea, select {
	font-family: $font-family-sans-serif;
	border-radius: $radius-sm;
	border: 1px solid $gray-300;
	padding: 0.25rem;
	width: 100%;
	font-size: 1rem;
	color: $dark;
}

input[type=number] {
	border: 0;
	width: 2.2em;
	padding: 0.25rem;
	background: $gray-100;
	border-radius: $radius-sm;
	text-align: center;
	font-size: 1.5rem;
	color: $dark;
}

input[type=radio] {
	position: relative;
	height: 1.25em;
	width: 1.25em;
	border: 2px solid $primary;
	border-radius: $radius-circle;
	margin: 0;
	margin-top: -0.15em;
	cursor: pointer;

	&:checked::before {
		content: '';
		position: absolute;
		width: 0.75em;
		height: 0.75em;
		background: $primary;
		border-radius: $radius-circle;
		left: calc(0.25em / 2);
		top: calc(0.25em / 2);
	}
}

label.required::after {
	content: '*';
	font-weight: $font-weight-bolder;
	color: $tertiary;
	margin-left: 0.5rem;
}

label.radio {
	display: grid;
	grid-auto-flow: column;
	align-items: center;
	gap: 0.25rem;
}

.error {
	grid-column: 1 / 3;
	background: $peach-200;
	padding: 1rem;
	display: grid;
	grid-auto-flow: column;
	grid-auto-columns: 1.25rem auto;
	align-items: center;
	column-gap: 1rem;
	color: $peach-600;
	font-weight: $font-weight-medium;
	border-radius: $radius-lg;

	&::before {
		content: '!';
		border: 2px solid $peach-600;
		border-radius: $radius-circle;
		width: 2em;
		height: 2em;
		display: grid;
		align-items: center;
		justify-content: center;
		font-weight: $font-weight-bolder;
		font-size: 0.75em;
		color: $peach-600;
		padding-top: 0.25em;
	}
}