#stepper { margin-bottom: 3rem; }

#reservation {
	margin-top: 3rem;

	h2 {
		text-align: center;
		margin-bottom: 3rem;
	}
}

// #reservation-information, #reservation-confirmation {
// 	position: absolute;
// 	top: 0;
// 	left: 0;
// 	height: 100%;
// 	width: 100%;
// 	background: $white;
// 	transition: all ease-in-out 0.5s;
// }

#reservation-confirmation {
	.row {
		display: grid;
		grid-auto-flow: row;
		// grid-template-columns: 3fr 4fr;
		gap: 2rem;
	}
	
	.col:nth-child(1) { grid-area: 2 / 1 / 3 / 2; }
	.col:nth-child(2) { grid-area: 1 / 1 / 2 / 2; }

	img {
		width: 100%;
		border-radius: $radius-lg;
	}

	h2, .date, .phone, .address { margin-bottom: 2rem; }

	.date {
		font-weight: $font-weight-medium;
		color: $primary;
	}

	.address { margin-top: 3.5rem; }
}

// .date-time {
// 	margin-top: 3rem;
// 	margin-bottom: 3rem;
// 	display: grid;
// 	grid-template-columns: max-content auto;
// 	gap: 3rem;
// }

// .calendar {
// 	display: grid;
// 	grid-auto-flow: row;
// 	gap: 1rem;
// }

// .cal-title {
// 	display: grid;
// 	grid-auto-flow: column;
// 	gap: 1rem;
// 	justify-content: space-between;
// 	align-items: center;

// 	.icon { height: 0.875rem; }
// }

// .cal-days, .cal-dates {
// 	display: grid;
// 	grid-template-columns: repeat(7, minmax(0, 1fr));
// 	gap: 1rem;
// }

// .cal-day, .cal-date {
// 	display: grid;
// 	align-content: center;
// 	justify-content: center;
// 	width: 1.5rem;
// 	height: 1.5rem;
// 	border: 2px solid rgba($dark, 0);
// 	border-radius: calc($radius-sm / 2);
// 	font-size: 0.875rem;
// 	line-height: 1;

// 	&.disabled { color: $gray-200; }
// 	&.today { border-color: $dark; }
// 	&.selected {
// 		border-color: $dark;
// 		background-color: $dark;
// 		color: $white;
// 	}

// 	&.weekend {
// 		color: $tertiary;

// 		&.disabled { color: $gray-200; }
// 		&.today { border-color: $tertiary; }
// 		&.selected {
// 			border-color: $tertiary;
// 			background-color: $tertiary;
// 			color: $dark;
// 		}
// 	}
// }

// .time-select {
// 	display: grid;
// 	grid-auto-flow: row;
// 	align-content: flex-start;
// 	gap: 0.25rem;
// }

// .time-title {
// 	display: grid;
// 	grid-template-columns: auto auto;
// 	justify-content: space-between;

// 	.icon {
// 		height: 1.5rem;
// 		width: 1.5rem;
// 		border-radius: $radius-circle;
// 		border: 5px solid $gray-100;
// 		background-color: $gray-100;

// 		&:not(:last-child) { margin-right: 0.5rem; }
// 	}
// }

// .time-chips {
// 	display: grid;
// 	grid-template-columns: repeat(4, minmax(0, 1fr));
// 	gap: 1rem;
// }

fieldset {
	display: grid;
	grid-template-columns: auto auto;
	justify-content: space-between;
	align-items: center;
	column-gap: 2rem;
	row-gap: 0.5rem;
	margin-top: 1.5rem;
	margin-bottom: 1.5rem;

	&.fullwidth { grid-template-columns: 85px 1fr; }
	
	.col:nth-child(2) {
		display: grid;
		grid-auto-flow: column;
		justify-content: flex-end;
		align-items: center;
		gap: 1rem;
	}

	.icon {
		height: 1rem;
		width: 1rem;
	}
}

.form-buttons {
	display: grid;
	grid-auto-flow: column;
	justify-content: space-between;
	gap: 1.5rem;
}